import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" style={{ padding: "40px 0px 60px 0px" }}>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            {" "}
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            {" "}
            Quality web design is an extremely important factor of increasing
            online presence. It has the ability to convert visitors to loyal and
            paying clients. If a website isn’t appealing to online consumers,
            they will turn away and never visit it again.{" "}
          </p>
          <p className={classes.description}>
            The expectations of consumers evolve on a daily basis. In Vancouver,
            a web designer must keep track of the latest design trends. From
            using advanced hosting platforms to mobile optimization, a
            professional website should effectively engage visitors and convert
            leads into clients.{" "}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
