import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import styles from "../../jss/landing-webdesign.js"

const getImages = graphql`
  {
    file(relativePath: { eq: "seo-vancouver.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div
      className={classNames(classes.section, classes.sectionDark)}
      style={{ padding: "60px 0px 80px 0px" }}
    >
      <Grid container justify="center" alignItems="center" spacing={4}>
        <Grid item md={8} sm={10} lg={5} xs={10}>
          <Img fluid={data.file.childImageSharp.fluid} alt="vancouver-seo" />
        </Grid>
        <Grid item md={6} sm={8} lg={5} xs={11}>
          <h3 className={classes.title}>Launch with Confidence</h3>
          <h6 className={classes.description}> Vancouver SEO </h6>
          <p className={classes.description}>
            Atlas Agency uses proven methods to strengthen the online presence
            of your website. We cover every aspect of SEO including keyword
            research, competitor analysis, backlink building, and content
            marketing. When you hire us, you get full SEO management services.{" "}
          </p>
          <p className={classes.description}>
            Our Vancouver SEO team of experts ensure you receive consistent
            traffic and organic leads to your web page. Contact us and find out
            more about our services. Let us help launch your website straight to
            Google’s first results.{" "}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
