import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"
import SpellcheckIcon from "@material-ui/icons/Spellcheck"

import CodeIcon from "@material-ui/icons/Code"

import LinkIcon from "@material-ui/icons/Link"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section} style={{ padding: "40px 0px 60px 0px" }}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>SEO Vancouver </h2>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={11} align="center">
          <p className={classes.description}>
            Atlas Agency is a one stop shop for all your digital marketing
            needs. We have professional web developers that can help you build a
            website that is optimized for Google’s search engine. Invest in a
            quality website and achieve higher ranking in Google searches. Our
            team will help you research, plan and launch a professional website.{" "}
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Back Linking"
              description="Google ranks websites based on their keywords, citations and mobile-friendly-ness. The more citations i.e. links that refer to your website, the higher you will rank on Google. Atlas Agency guarantees results by using white-hat back linking to connect your website to others on the world wide web and strengthen your position in Google’s results."
              icon={LinkIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Keyword Research"
              description="Drastically improve your online presence by ranking for quality keywords. At Atlas Agency, we focus on every client independently, providing personalized keyword analysis. We identify which keywords are most searched by your potential customer and ensure you rank highest for each of them. "
              icon={SpellcheckIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="META Tag Optimization"
              description="These short tags are only visible in HTML and help Google define your pages keywords.Used correctly, meta tags play a major role in your Google ranking. We identify the keywords for your industry and then optimize your website. We help you achieve ranking for keywords with the highest search volume."
              icon={CodeIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
